.topHeader{
    padding-top: 5%;
    padding-left:5%;
    padding-right:5%;
    background-color: white !important;
}

.topHeaderContainer{
    padding: 0;
}


.nav-link.active{
    border-bottom: 4px solid #005B7F;
}


@media (min-width: 992px){  
.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 20px;
    padding-right: 20px;
}
}